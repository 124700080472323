import PropTypes from 'prop-types'
import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

const LeafletMap = ({ position, zoom, markerText, height, width }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            position
          }
        }
      }
    `
  )

  const metaPosition = position || site.siteMetadata.position
  const metaMarkerText = markerText || site.siteMetadata.description

  if (typeof window !== 'undefined') {
    return (
      <MapContainer
        center={metaPosition}
        zoom={zoom}
        style={{ height: height, width: width }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution=""
        />
        <Marker position={metaPosition}>
          <Popup>{metaMarkerText}</Popup>
        </Marker>
      </MapContainer>
    )
  }
  return null
}

LeafletMap.propTypes = {
  /** Latitude and Longitude of the map centre in an array, eg [51, -1] **/
  position: PropTypes.array,
  /** Initial zoom level for the map (default 13) **/
  zoom: PropTypes.number,
  /** If set, will display a marker, which when clicked will display this text **/
  markerText: PropTypes.string,

  height: PropTypes.string,
  width: PropTypes.string
}

LeafletMap.defaultProps = {
  zoom: 12,
  height: '400px',
  width: '300px'
}

export default LeafletMap
