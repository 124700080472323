import React from 'react'

import { Link } from 'gatsby'

import { BsPhone, BsFillEnvelopeFill, BsGeoAlt, BsAt } from 'react-icons/bs'
import { FaFacebook, FaHandsHelping, FaInstagram } from 'react-icons/fa'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MyPage from '../components/my_page'
import LeafletMap from '../components/leaflet_map'

const KontaktaiPage = () => {
  const pageTitle = 'Kontaktai'
  const pageContent = (
    <span>
      <Row>
        <Col>
          <p>
            Darželis yra įsikūręs Antežeriuose, patogioje vietoje, greta
            Vilniaus miesto vakarinio aplinkkelio. Juo patogiai atvyksite iš
            įvairių Vilniaus miesto rajonų (ypač Pilaitės, Perkūnkiemio,
            Pašilaičių, Tarandės ir Zujūnų).
          </p>
          <p>
            {' '}
            <BsGeoAlt size={20} /> Ežero g. 46, Vilniaus raj. sav., Antežerių
            k., LT - 14158{' '}
          </p>
          <ul>
            <li>
              <BsPhone size={20} />
              Agnė +370 671 72480
            </li>
            <li>
              <BsPhone size={20} />
              Evelina +370 674 72530
            </li>
          </ul>
          <p>
            Prašome skambinti DARBO DIENOMIS 7:30-18:00 val. Savaitgaliais ir
            švenčių dienomis NEDIRBAME.
          </p>
          <p>
            Domėkitės taip pat ir el. paštu <br />
            <BsFillEnvelopeFill size={20} /> &nbsp; musu.pedutes <BsAt />{' '}
            gmail.com
          </p>
        </Col>
        <Col>
          <LeafletMap />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            <a href="https://www.facebook.com/musu.pedutes/">
              <FaFacebook size={20} /> &nbsp; Facebook profilis
            </a>
          </p>
          <p>
            <a href="https://www.instagram.com/musu.pedutes/">
              <FaInstagram size={20} /> &nbsp; Instagram profilis
            </a>
          </p>
          <p>
            <FaHandsHelping size={20} />
            &nbsp; Galite padėti Mūsų pėdutėms žengti dar tvirtesniais
            žingsniais skirdami 1,2% gyventojų pajamų mokesčio.
          </p>
          <address>
            <strong>VšĮ „Mūsų pėdutės“</strong>
            <br />
            Architektų g. 200-41, <br />
            Vilnius, LT - 04213
            <br />
            Įm. k. 304111570
            <br />
            Sąsk. nr. LT77 7300 0101 4476 7484 (Swedbank AB)
            <br />
          </address>
        </Col>
      </Row>
    </span>
  )

  return (
    <MyPage pageType="simple" pageTitle={pageTitle} pageContent={pageContent} />
  )
}

export default KontaktaiPage
